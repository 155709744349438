<template>
  <div>
    <CostsNav/>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions,mapState,mapMutations  } from "vuex";
import CostsNav from '@/modules/fivesClub/components/profit/costs/CostsNav'
// import AnualPerformance from '@/modules/fivesClub/components/profit/costs/ListAnualPerformance'
export default {
   async mounted() {
  if(this.rentalPoolFilter.length!=0)return false
      let rentalPool= await this.fetchGetRentalPoolFilter();
      this.setRentalPoolFilter(rentalPool)
  },
  components:{
    CostsNav,
    // AnualPerformance
  },
  computed:{
    ...mapState("fivesClubProfit", ['rentalPoolFilter']),
  },
   methods: {
    ...mapMutations('fivesClubProfit', ['setRentalPoolFilter']),
    ...mapActions('fivesClubProfit', ["fetchGetRentalPoolFilter"]),
  },
};
</script>
